.sprite-textures {
  background-image: url("./res/img/textures.png");
  background-repeat: no-repeat;
  display: inline-block;
  overflow: hidden;
}

.textures-icon-pointer_disabled {
  pointer-events: none;
}

.textures-icon-shop_item {
  scale: .8;
}

.textures-attack {
  width: 62px;
  height: 60px;
  background-position: -967px -1287px;
}

.textures-bag_1 {
  width: 128px;
  height: 124px;
  background-position: -674px -811px;
}

.textures-bag_2 {
  width: 128px;
  height: 193px;
  background-position: -1258px -216px;
}

.textures-bag_3 {
  width: 128px;
  height: 136px;
  background-position: -524px -905px;
}

.textures-bonus_etazh {
  width: 54px;
  height: 60px;
  background-position: -1704px -1186px;
}

.textures-bonus_money {
  width: 63px;
  height: 60px;
  background-position: -1532px -330px;
}

.textures-bonus_sale {
  width: 57px;
  height: 60px;
  background-position: -1529px -594px;
}

.textures-book {
  width: 120px;
  height: 120px;
  background-position: -934px -857px;
}

.textures-button-arrow_left {
  width: 95px;
  height: 149px;
  background-position: -1248px -600px;
}

.textures-button-arrow_right {
  width: 95px;
  height: 149px;
  background-position: -1345px -600px;
}

.textures-coffee_0 {
  width: 128px;
  height: 98px;
  background-position: -264px -1038px;
}

.textures-coffee_1 {
  width: 128px;
  height: 129px;
  background-position: -654px -937px;
}

.textures-coffee_2 {
  width: 128px;
  height: 129px;
  background-position: -394px -1034px;
}

.textures-coffee_2_green {
  width: 128px;
  height: 98px;
  background-position: -834px -743px;
}

.textures-coffee_2_pattern {
  width: 128px;
  height: 129px;
  background-position: -524px -1043px;
}

.textures-coffee_3 {
  width: 94px;
  height: 129px;
  background-position: -1056px -857px;
}

.textures-decor-frame-0 {
  width: 118px;
  height: 118px;
  background-position: -1248px -835px;
}

.textures-develop {
  width: 100px;
  height: 97px;
  background-position: -1378px -751px;
}

.textures-dice_question-green {
  width: 100px;
  height: 105px;
  background-position: -1035px -988px;
}

.textures-freeze {
  width: 122px;
  height: 114px;
  background-position: -419px -769px;
}

.textures-gadget-a-0 {
  width: 140px;
  height: 184px;
  background-position: -1116px -216px;
}

.textures-gadget-a-icon {
  width: 100px;
  height: 78px;
  background-position: -1354px -1107px;
}

.textures-gadget-b-0 {
  width: 273px;
  height: 329px;
  background-position: -2px -518px;
}

.textures-gadget-b-icon {
  width: 106px;
  height: 128px;
  background-position: -255px -1266px;
}

.textures-gadget-c-0 {
  width: 152px;
  height: 267px;
  background-position: -545px -518px;
}

.textures-gadget-c-icon {
  width: 119px;
  height: 125px;
  background-position: -914px -979px;
}

.textures-gadget-d-0 {
  width: 142px;
  height: 179px;
  background-position: -1388px -200px;
}

.textures-gadget-d-icon {
  width: 128px;
  height: 125px;
  background-position: -654px -1068px;
}

.textures-gadget-e-0 {
  width: 141px;
  height: 223px;
  background-position: -834px -518px;
}

.textures-gadget-e-icon {
  width: 128px;
  height: 126px;
  background-position: -233px -1138px;
}

.textures-gadget-f-0 {
  width: 139px;
  height: 164px;
  background-position: -977px -626px;
}

.textures-gadget-f-icon {
  width: 128px;
  height: 70px;
  background-position: -1116px -402px;
}

.textures-gadget-g-0 {
  width: 133px;
  height: 291px;
  background-position: -699px -518px;
}

.textures-gadget-g-icon {
  width: 59px;
  height: 128px;
  background-position: -1532px -200px;
}

.textures-gadget-h-0 {
  width: 229px;
  height: 214px;
  background-position: -2px -1107px;
}

.textures-gadget-h-icon {
  width: 128px;
  height: 120px;
  background-position: -363px -1165px;
}

.textures-gadget-i-0 {
  width: 135px;
  height: 167px;
  background-position: -977px -457px;
}

.textures-gadget-i-icon {
  width: 103px;
  height: 128px;
  background-position: -1042px -1095px;
}

.textures-gadget-test-0 {
  width: 183px;
  height: 190px;
  background-position: -1549px -2px;
}

.textures-GARBAGE {
  width: 256px;
  height: 256px;
  background-position: -710px -260px;
}

.textures-gui-larger {
  width: 34px;
  height: 34px;
  background-position: -1094px -821px;
}

.textures-gui-smaller {
  width: 34px;
  height: 34px;
  background-position: -1687px -1352px;
}

.textures-heal {
  width: 266px;
  height: 249px;
  background-position: -277px -518px;
}

.textures-hoodie_2 {
  width: 129px;
  height: 116px;
  background-position: -543px -787px;
}

.textures-hoodie {
  width: 112px;
  height: 127px;
  background-position: -1368px -850px;
}

.textures-icon-checkmark {
  width: 50px;
  height: 48px;
  background-position: -1709px -262px;
}

.textures-icon-clock-16px {
  width: 16px;
  height: 16px;
  background-position: -865px -1269px;
}

.textures-icon-clock {
  width: 68px;
  height: 68px;
  background-position: -1672px -614px;
}

.textures-icon-coffee {
  width: 88px;
  height: 100px;
  background-position: -1242px -1274px;
}

.textures-icon-cog {
  width: 50px;
  height: 49px;
  background-position: -1549px -1037px;
}

.textures-icon-cross {
  width: 96px;
  height: 95px;
  background-position: -1147px -1176px;
}

.textures-icon-gem-24px {
  width: 24px;
  height: 22px;
  background-position: -1089px -216px;
}

.textures-icon-gem-36px {
  width: 36px;
  height: 33px;
  background-position: -967px -1349px;
}

.textures-icon-gem {
  width: 81px;
  height: 74px;
  background-position: -1564px -792px;
}

.textures-icon-gold {
  width: 81px;
  height: 79px;
  background-position: -1647px -883px;
}

.textures-icon-journal {
  width: 50px;
  height: 50px;
  background-position: -1442px -694px;
}

.textures-icon-locations {
  width: 107px;
  height: 87px;
  background-position: -1245px -1083px;
}

.textures-icon-music-muted {
  width: 82px;
  height: 82px;
  background-position: -1588px -614px;
}

.textures-icon-music {
  width: 65px;
  height: 71px;
  background-position: -1695px -1048px;
}

.textures-icon-pawn {
  width: 81px;
  height: 92px;
  background-position: -1564px -698px;
}

.textures-icon-pvp {
  width: 118px;
  height: 81px;
  background-position: -722px -1199px;
}

.textures-icon-shop {
  width: 97px;
  height: 93px;
  background-position: -623px -1195px;
}

.textures-icon-sound-muted {
  width: 38px;
  height: 70px;
  background-position: -1465px -463px;
}

.textures-icon-sound {
  width: 69px;
  height: 70px;
  background-position: -1624px -1048px;
}

.textures-icon-star {
  width: 50px;
  height: 50px;
  background-position: -1494px -694px;
}

.textures-lock_closed-blue {
  width: 82px;
  height: 113px;
  background-position: -493px -1290px;
}

.textures-lock_closed-gray {
  width: 82px;
  height: 113px;
  background-position: -577px -1290px;
}

.textures-lock_closed-mq-blue {
  width: 48px;
  height: 66px;
  background-position: -1709px -194px;
}

.textures-lock_closed-red-128px {
  width: 96px;
  height: 127px;
  background-position: -1147px -1047px;
}

.textures-lock_closed-red {
  width: 146px;
  height: 195px;
  background-position: -968px -260px;
}

.textures-lock_closed-white {
  width: 82px;
  height: 113px;
  background-position: -661px -1290px;
}

.textures-lock_closed {
  width: 82px;
  height: 113px;
  background-position: -1465px -979px;
}

.textures-lootpack-0-open {
  width: 140px;
  height: 114px;
  background-position: -277px -769px;
}

.textures-lootpack-0 {
  width: 128px;
  height: 114px;
  background-position: -493px -1174px;
}

.textures-lootpack-1-128px {
  width: 128px;
  height: 110px;
  background-position: -363px -1287px;
}

.textures-lootpack-1-open-128px {
  width: 128px;
  height: 126px;
  background-position: -804px -843px;
}

.textures-lootpack-1-open {
  width: 260px;
  height: 256px;
  background-position: -2px -849px;
}

.textures-lootpack-1 {
  width: 299px;
  height: 256px;
  background-position: -788px -2px;
}

.textures-lootpack-2-128px {
  width: 128px;
  height: 89px;
  background-position: -1118px -730px;
}

.textures-lootpack-2-open-128px {
  width: 128px;
  height: 98px;
  background-position: -784px -1099px;
}

.textures-lootpack-2-open {
  width: 335px;
  height: 256px;
  background-position: -373px -260px;
}

.textures-lootpack-2 {
  width: 369px;
  height: 256px;
  background-position: -2px -260px;
}

.textures-lootpack-3-128px {
  width: 128px;
  height: 79px;
  background-position: -1246px -411px;
}

.textures-lootpack-3-open-128px {
  width: 115px;
  height: 81px;
  background-position: -914px -1204px;
}

.textures-lootpack-3-open {
  width: 366px;
  height: 256px;
  background-position: -420px -2px;
}

.textures-lootpack-3 {
  width: 416px;
  height: 256px;
  background-position: -2px -2px;
}

.textures-manager_girl1_cr {
  width: 73px;
  height: 92px;
  background-position: -1686px -416px;
}

.textures-manager_girl2_cr {
  width: 72px;
  height: 92px;
  background-position: -1647px -698px;
}

.textures-manager_girl3_cr {
  width: 88px;
  height: 100px;
  background-position: -1512px -1289px;
}

.textures-manager_girl4_cr {
  width: 87px;
  height: 100px;
  background-position: -1530px -1187px;
}

.textures-manager_girl5_cr {
  width: 87px;
  height: 93px;
  background-position: -1597px -321px;
}

.textures-manager_girl6_cr {
  width: 83px;
  height: 92px;
  background-position: -1681px -518px;
}

.textures-manager_girl7_cr {
  width: 78px;
  height: 93px;
  background-position: -1686px -321px;
}

.textures-manager_girl8_cr {
  width: 83px;
  height: 100px;
  background-position: -1602px -1289px;
}

.textures-manager_girl9_cr {
  width: 83px;
  height: 100px;
  background-position: -1619px -1187px;
}

.textures-manager_girl10_cr {
  width: 86px;
  height: 100px;
  background-position: -1442px -1187px;
}

.textures-manager_girl11_cr {
  width: 77px;
  height: 91px;
  background-position: -1545px -1094px;
}

.textures-manager_girl12_cr {
  width: 88px;
  height: 90px;
  background-position: -1332px -1288px;
}

.textures-manager_girl13_cr {
  width: 78px;
  height: 91px;
  background-position: -1465px -1094px;
}

.textures-manager_girl14_cr {
  width: 88px;
  height: 100px;
  background-position: -1422px -1289px;
}

.textures-manager_girl15_cr {
  width: 97px;
  height: 100px;
  background-position: -1245px -1172px;
}

.textures-manager_male1_cr {
  width: 96px;
  height: 99px;
  background-position: -1344px -1187px;
}

.textures-manager_male2_cr {
  width: 87px;
  height: 96px;
  background-position: -1376px -463px;
}

.textures-manager_male3_cr {
  width: 86px;
  height: 94px;
  background-position: -1593px -518px;
}

.textures-manager_male4_cr {
  width: 85px;
  height: 98px;
  background-position: -1510px -392px;
}

.textures-manager_male5_cr {
  width: 82px;
  height: 98px;
  background-position: -1480px -746px;
}

.textures-manager_male6_cr {
  width: 95px;
  height: 99px;
  background-position: -1145px -1273px;
}

.textures-manager_male7_cr {
  width: 85px;
  height: 98px;
  background-position: -1442px -594px;
}

.textures-manager_male8_cr {
  width: 93px;
  height: 100px;
  background-position: -1152px -945px;
}

.textures-manager_male9_cr {
  width: 79px;
  height: 97px;
  background-position: -1482px -846px;
}

.textures-manager_male10_cr {
  width: 86px;
  height: 100px;
  background-position: -1505px -492px;
}

.textures-manager_male11_cr {
  width: 87px;
  height: 100px;
  background-position: -1597px -416px;
}

.textures-nakleika {
  width: 256px;
  height: 212px;
  background-position: -1089px -2px;
}

.textures-notebook {
  width: 128px;
  height: 126px;
  background-position: -784px -971px;
}

.textures-notepad_write {
  width: 70px;
  height: 68px;
  background-position: -842px -1199px;
}

.textures-poison {
  width: 94px;
  height: 122px;
  background-position: -1152px -821px;
}

.textures-rank-0 {
  width: 82px;
  height: 82px;
  background-position: -1563px -868px;
}

.textures-rank-1 {
  width: 82px;
  height: 82px;
  background-position: -1633px -964px;
}

.textures-rank-2 {
  width: 82px;
  height: 83px;
  background-position: -1549px -952px;
}

.textures-rank-3 {
  width: 120px;
  height: 80px;
  background-position: -1388px -381px;
}

.textures-rank-4 {
  width: 127px;
  height: 80px;
  background-position: -2px -1323px;
}

.textures-rank-5 {
  width: 122px;
  height: 80px;
  background-position: -131px -1323px;
}

.textures-shield {
  width: 114px;
  height: 125px;
  background-position: -1593px -194px;
}

.textures-shnurok {
  width: 118px;
  height: 117px;
  background-position: -745px -1282px;
}

.textures-sign {
  width: 77px;
  height: 61px;
  background-position: -1687px -1289px;
}

.textures-slowdown {
  width: 126px;
  height: 96px;
  background-position: -914px -1106px;
}

.textures-speed {
  width: 112px;
  height: 122px;
  background-position: -1031px -1225px;
}

.textures-stamp {
  width: 100px;
  height: 100px;
  background-position: -865px -1287px;
}

.textures-support_1-64px {
  width: 64px;
  height: 63px;
  background-position: -1624px -1120px;
}

.textures-support_1-red {
  width: 128px;
  height: 126px;
  background-position: -1114px -474px;
}

.textures-support_1 {
  width: 128px;
  height: 126px;
  background-position: -1118px -602px;
}

.textures-support_2 {
  width: 128px;
  height: 63px;
  background-position: -964px -792px;
}

.textures-support_3 {
  width: 128px;
  height: 82px;
  background-position: -1248px -751px;
}

.textures-task-a-0-64px {
  width: 64px;
  height: 63px;
  background-position: -1690px -1121px;
}

.textures-task-a-0 {
  width: 200px;
  height: 196px;
  background-position: -1347px -2px;
}

.textures-task_1-h126px-red {
  width: 107px;
  height: 126px;
  background-position: -1247px -955px;
}

.textures-task_1-h126px {
  width: 107px;
  height: 126px;
  background-position: -1356px -979px;
}

.textures-task_1 {
  width: 128px;
  height: 151px;
  background-position: -264px -885px;
}

.textures-task_2 {
  width: 128px;
  height: 147px;
  background-position: -394px -885px;
}

.textures-task_3 {
  width: 128px;
  height: 106px;
  background-position: -1244px -492px;
}

.textures-test_icon_1 {
  width: 71px;
  height: 89px;
  background-position: -1647px -792px;
}

div#syserrorpanel {
  z-index: 11;
  width: 100%;
  height: 100%;
  pointer-events: auto;
  background: linear-gradient(0deg, #000000c0, #454545c0 200%);
  flex-direction: column;
  justify-content: center;
  display: flex;
  position: absolute;
}

div#syserrorpanel entry {
  width: 90%;
  color: #f5ff6b;
  pointer-events: none;
  justify-content: center;
  font-size: 3vw;
  font-weight: bolder;
  display: flex;
}

div#syserrorpanel.hidden {
  display: none;
}

div#syserrorpanel > * {
  margin: 0 auto 3vw;
}

/*# sourceMappingURL=index.af65b02e.css.map */
