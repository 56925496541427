@import './textures.less';

div#syserrorpanel {
	entry {
		width: 90%;

		display: flex;
		justify-content: center;
		color: #f5ff6b;
		font-weight: 600;
		font-size: 3vw;
		font-weight: bolder;
		pointer-events: none;
	}

	&.hidden {
		display: none;
	}

	background: linear-gradient(0deg, #000000c0, #454545c0 200%);
	position: absolute;
	z-index: 11;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 100%;
	pointer-events: auto;

	& > * {
		margin: 0vw auto 3vw;
	}
}
